import { TextField, styled } from '@mui/material';

export const StyledTextField = styled(TextField)(({ theme }) => ({
    height: '100%',

    '& .MuiOutlinedInput-root': {
        padding: '13px 17px',
        ...theme.fonts.body_2,
    },

    '& .MuiAutocomplete-input': {
        padding: '0px!important',
    },
}));
