import {
    Autocomplete,
    Icon,
    IconButton,
    InputAdornment,
    OutlinedInputProps,
} from '@mui/material';
import { StyledTextField } from './CustomLocationsAutocompleteInput.styled';
import useSWR from 'swr';
import { ReactElement, useState } from 'react';
import { capitalize } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import CustomExpandIcon from 'components/SVGIcons/CustomExpandIcon';
import get from 'lodash/get';
import { debounce } from 'lodash';
import { SxProps } from '@mui/system';
import { getLocationSuggestions } from 'pages/api/getLocationAutocomplete';

export const getLocationData = (
    location: { offset: number; value: string }[]
) => {
    let city;
    let state;
    let country;

    if (location.length === 3) {
        city = location[0]?.value.trim();
        state = location[1]?.value.trim();
        country = location[2]?.value.trim();
    } else {
        city = location[0]?.value.trim();
        state = '';
        country = location[1]?.value.trim();
    }

    return {
        city,
        state,
        country,
    };
};
export interface Match {
    title: string;
    city: string | undefined;
    region: string | undefined;
    country: string | undefined;
}

interface BaseProps {
    label?: string;
    autocompleteFormKey: string;
    icon?: ReactElement;
    styleAutocomplete?: SxProps;
    styleInput?: SxProps;
    placeholder?: string;
    additionalHandler?: (match?: Match) => void;
    InputProps?: Partial<OutlinedInputProps>;
}

interface LocationStringProps extends BaseProps {
    withDetailedData: false;
}

interface LocationDetailedDataProps extends BaseProps {
    withDetailedData: true;
    cityFormKey: string;
    regionFormKey: string;
    countryFormKey: string;
}

type Props = LocationStringProps | LocationDetailedDataProps;

const CustomLocationsAutocompleteInput: React.FC<Props> = (props) => {
    const {
        label,
        autocompleteFormKey,
        icon,
        styleAutocomplete,
        styleInput,
        placeholder,
        additionalHandler,
        InputProps,
        withDetailedData,
    } = props;

    const {
        control,
        setValue,
        formState: { isSubmitting, errors },
        trigger,
    } = useFormContext();

    const [inputValue, setInputValue] = useState('');
    const debouncedSetValue = debounce(setInputValue, 300);

    const locationsFetcher = async (value: string) => {
        const { data } = await getLocationSuggestions(value);

        const res = data.predictions.map((el) => {
            const { city, state, country } = getLocationData(el.terms);
            return {
                title: el.description,
                city: city,
                region: state,
                country: country,
            };
        });

        return res;
    };

    const { data: predictions, isLoading } = useSWR(
        inputValue.length >= 2 ? inputValue : null,
        locationsFetcher
    );

    const titles = predictions?.map((el) => el.title) || [];

    const errorMessage = get(errors, autocompleteFormKey)?.message;

    const clearButtonHandler = () => {
        setValue(autocompleteFormKey, '');

        if (withDetailedData) {
            const { cityFormKey, regionFormKey, countryFormKey } = props;

            setValue(cityFormKey, '');
            setValue(regionFormKey, '');
            setValue(countryFormKey, '');
        }
    };

    return (
        <Controller
            name={autocompleteFormKey}
            control={control}
            render={({ field: { onChange, onBlur, ...field } }) => (
                <Autocomplete
                    {...field}
                    fullWidth
                    disabled={isSubmitting}
                    popupIcon={
                        <Icon>
                            <CustomExpandIcon sx={{ width: '10px' }} />
                        </Icon>
                    }
                    sx={styleAutocomplete}
                    loading={isLoading}
                    options={titles}
                    onChange={(_, value, reason) => {
                        const match = predictions?.find(
                            ({ title }) => title === value
                        );
                        value && onChange(value);

                        if (withDetailedData) {
                            const {
                                cityFormKey,
                                regionFormKey,
                                countryFormKey,
                            } = props;
                            setValue(cityFormKey, match?.city ?? '');
                            setValue(regionFormKey, match?.region ?? '');
                            setValue(countryFormKey, match?.country ?? '');
                        }

                        if (reason === 'clear') clearButtonHandler();

                        additionalHandler && additionalHandler(match);
                    }}
                    noOptionsText={'No locations'}
                    renderInput={(params) => (
                        <StyledTextField
                            {...params}
                            {...field}
                            placeholder={placeholder}
                            label={capitalize(label)}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                ...params.InputProps,
                                ...InputProps,
                                autoComplete: 'disabled',
                                startAdornment: icon && (
                                    <InputAdornment position="start">
                                        <IconButton edge="start">
                                            {icon}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                sx: styleInput,
                            }}
                            onChange={(e) => debouncedSetValue(e.target.value)}
                            onBlur={() => {
                                onBlur();
                                trigger(autocompleteFormKey);
                            }}
                            error={!!errorMessage}
                            helperText={errorMessage}
                            disabled={isSubmitting}
                        />
                    )}
                />
            )}
        />
    );
};

export default CustomLocationsAutocompleteInput;
