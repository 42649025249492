import { InputLabel, MenuItem, Select, styled } from '@mui/material';

export const StyledSelect = styled(Select)(({ theme }) => ({
    width: '100%',

    '& .MuiInputBase-input': {
        ...theme.fonts.body_2,
        padding: '12px 17px',
    },
}));

export const StyledLabel = styled(InputLabel)({});

export const LoaderWrapper = styled('div')(() => ({
    width: '100%',
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const StyledMenuItem = styled(MenuItem)(
    ({ theme: { colors }, selected }) => ({
        color: selected ? colors.primaryColor : 'auto',

        '& :before': {
            content: "''",
            position: 'absolute',
            height: '100%',
            width: '3px',
            background: colors.primaryColor,
            display: selected ? 'auto' : 'none',
        },
    })
);
