import CustomInput from 'shared/formElements/CustomInput';
import { FormNode } from 'types/signUpTypes';
import _ from 'lodash';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import CustomLocationsAutocompleteInput from 'shared/formElements/CustomLocationsAutocompleteInput';
import CustomPhoneInput from 'shared/formElements/CustomPhoneInput';
import CustomMultilineInput from 'shared/formElements/CustomMultilineInput';
import CustomDropZone from 'shared/formElements/CustomDropZone';
import { Match } from 'shared/formElements/CustomLocationsAutocompleteInput/CustomLocationsAutocompleteInput';
import CustomSelectInput from 'shared/formElements/CustomSelectInput';

interface ComponentMap {
    fieldNode: FixedFormNode;
    formErrors: FieldErrors<{
        [key: string]: string;
    }>;
    progress?: number;
    additionalHandler?: (match?: Match) => void;
}

export interface FixedFormNode extends FormNode {
    type: string;
    tag: string;
}

export const getElementsToSelect = (el: FixedFormNode) =>
    el.choices.map((choice) => {
        return {
            value: String(choice[0]) || '',
            display_name: choice[1]?.toString() || '',
        };
    });

const FormField = ({
    fieldNode,
    progress,
    additionalHandler,
}: ComponentMap) => {
    const capitalized = (string: string) => _.capitalize(string);

    if (fieldNode.name.startsWith('location_')) return null;

    switch (fieldNode.fieldType) {
        case 'password': {
            return (
                <>
                    <CustomInput
                        borderRadius={4}
                        inputFormKey={fieldNode.name}
                        label={capitalized(fieldNode.helpText)}
                        type={'password'}
                        withPasswordValidation={true}
                        withEye
                    />
                    <CustomInput
                        borderRadius={4}
                        inputFormKey={`confirm_${fieldNode.name}`}
                        label={`Confirm ${capitalized(fieldNode.helpText)}`}
                        type={'password'}
                        withEye
                    />
                </>
            );
        }

        case 'dropdown': {
            return (
                <CustomSelectInput
                    itemsToSelect={getElementsToSelect(fieldNode)}
                    label={capitalized(fieldNode.helpText)}
                    formKey={fieldNode.name}
                />
            );
        }

        case 'location': {
            return (
                <CustomLocationsAutocompleteInput
                    label={capitalized(fieldNode.name)}
                    autocompleteFormKey={fieldNode.name}
                    cityFormKey="_location_city"
                    regionFormKey="_location_region"
                    countryFormKey="_location_country"
                    additionalHandler={additionalHandler}
                    withDetailedData
                />
            );
        }

        case 'singleline': {
            return (
                <CustomInput
                    borderRadius={4}
                    inputFormKey={fieldNode.name}
                    label={capitalized(fieldNode.helpText)}
                />
            );
        }

        case 'email': {
            return (
                <CustomInput
                    borderRadius={4}
                    inputFormKey={fieldNode.name}
                    label={capitalized(fieldNode.helpText)}
                />
            );
        }

        case 'number': {
            return <CustomPhoneInput formKey={fieldNode.name} />;
        }

        case 'multiline': {
            return (
                <CustomMultilineInput
                    borderRadius={4}
                    inputFormKey={fieldNode.name}
                    label={capitalized(fieldNode.helpText)}
                />
            );
        }

        case 'file': {
            return (
                <CustomDropZone formKey={fieldNode.name} progress={progress} />
            );
        }

        default:
            return null;
    }
};

export default FormField;
