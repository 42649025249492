import { FormNode } from 'types/signUpTypes';
import {
    z,
    ZodEffects,
    ZodString,
    ZodTypeAny,
    ZodNumber,
    ZodOptional,
    ZodArray,
    ZodAny,
    ZodUndefined,
} from 'zod';
import _ from 'lodash';
import {
    containCapitalLetter,
    containNumber,
    containSmallLetter,
} from './validationHelpers';

interface FixedFormNode extends FormNode {
    type: string;
    tag: string;
}

interface ValidationType {
    [key: string]:
        | ZodString
        | ZodNumber
        | ZodEffects<ZodTypeAny>
        | ZodOptional<ZodTypeAny>
        | ZodArray<ZodTypeAny>
        | ZodAny
        | ZodUndefined;
}

export const createSignUpValidator = (fields: FixedFormNode[]) => {
    const validator = fields.reduce((schema: ValidationType, formField) => {
        const { name, helpText, fieldType, required } = formField;
        const capitalized = (string: string) => _.capitalize(string);

        switch (fieldType) {
            case 'password': {
                schema[name] = z
                    .string()
                    .min(required ? 9 : 0, {
                        message: 'At least 9 characters in password',
                    })
                    .refine((password) => containNumber(password), {
                        message: 'At least one number character',
                    })
                    .refine((password) => containCapitalLetter(password), {
                        message: 'At least one capital letter',
                    })
                    .refine((password) => containSmallLetter(password), {
                        message: 'At least one small letter',
                    });

                schema[`confirm_${name}`] = z.string();

                break;
            }

            case 'dropdown': {
                schema[name] = z
                    .string()
                    .min(
                        required ? 1 : 0,
                        `${capitalized(helpText)} is required`
                    );

                break;
            }

            case 'location': {
                schema[name] = z
                    .string()
                    .min(
                        required ? 1 : 0,
                        `${capitalized(helpText)} is required`
                    );

                break;
            }

            case 'singleline': {
                if (name.includes('location_')) {
                    schema[`_${name}`] = z.string().optional();
                    break;
                }

                schema[name] = z.string().min(required ? 1 : 0, {
                    message: `${capitalized(helpText)} is required`,
                });
                break;
            }

            case 'email': {
                schema[name] = z
                    .string()
                    .email({ message: 'Please enter a valid email' })
                    .nonempty({
                        message: `${capitalized(helpText)} is required`,
                    });
                break;
            }

            case 'number': {
                schema[name] = z
                    .string()
                    .min(
                        required ? 5 : 0,
                        `${capitalized(helpText)} is required`
                    );
                break;
            }

            case 'multiline': {
                schema[name] = z
                    .string()
                    .min(
                        required ? 1 : 0,
                        `${capitalized(helpText)} is required`
                    );
                break;
            }

            case 'file': {
                schema[name] =
                    typeof window === 'undefined'
                        ? z.undefined()
                        : z
                              .array(z.instanceof(Blob))
                              .min(
                                  required ? 1 : 0,
                                  'Please select a document'
                              );
            }
        }

        return schema;
    }, {});

    const containsPassword = fields.find((field) => field.name === 'password');

    if (containsPassword) {
        return z.object(validator).refine(
            (data) => {
                return data.password === data.confirm_password;
            },
            {
                message: 'Password is different',
                path: ['confirm_password'],
            }
        );
    }

    return z.object(validator);
};

export const createDefaultFormValues = (fields: FixedFormNode[]) => {
    return fields.reduce((result: { [key: string]: string | [] }, obj) => {
        const { name, defaultValue, fieldType } = obj;

        const modifiedName = name.includes('location_') ? `_${name}` : name;

        result[modifiedName] = fieldType === 'file' ? [] : defaultValue;

        if (name === 'password') {
            result[`confirm_${name}`] = defaultValue;
        }

        return result;
    }, {});
};
