import { IconProps } from './types';

const GloProsIcon: React.FC<IconProps> = ({
    width,
    height = '100%',
    color = '#0053CD',
    style,
}) => {
    return (
        <svg
            style={style}
            width={width}
            height={height}
            viewBox="0 0 34 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M33.6063 0C33.6063 4.07722 30.283 7.38996 26.1927 7.38996H17.0536C12.0048 7.38996 7.91455 11.4672 7.91455 16.5V30.2606C3.40891 27.2664 0.437101 22.1062 0.501011 16.3089C0.596876 7.2307 8.13823 0 17.2454 0H33.6063Z"
                fill={color}
            />
            <path
                d="M31.2104 15.99H20.7292C20.7612 16.1493 20.7612 16.3085 20.7612 16.4997C20.7612 18.5383 19.0995 20.1946 17.0544 20.1946C15.0093 20.1946 13.3477 18.5383 13.3477 16.4997C13.3477 14.4611 15.0093 12.8047 17.0544 12.8047C17.1503 12.8047 17.2781 12.8047 17.374 12.8047H31.2104V15.99Z"
                fill={color}
            />
            <path
                d="M33.6071 16.4991C33.6071 17.1998 33.5751 17.9006 33.4792 18.6014C33.4473 18.8881 33.4153 19.1429 33.3514 19.4296C33.2236 20.0985 33.0638 20.7674 32.8721 21.4045C31.2424 26.5647 27.1522 30.6419 21.9755 32.2664C21.3364 32.4576 20.6653 32.6168 19.9943 32.7442C19.7386 32.8079 19.451 32.8398 19.1634 32.8717C18.4604 32.9672 17.7574 32.9991 17.0544 32.9991C16.8946 32.9991 16.7349 32.9991 16.5751 32.9991C16.4473 32.9991 16.2875 32.9991 16.1597 32.9672C16.0958 32.9672 15.9999 32.9672 15.936 32.9672C15.8721 32.9672 15.7762 32.9672 15.7123 32.9354C15.5845 32.9354 15.4886 32.9035 15.3608 32.9035C15.2649 32.9035 15.1371 32.8717 15.0413 32.8717C14.9454 32.8717 14.8495 32.8398 14.7537 32.8398C14.7217 32.8398 14.6898 32.8398 14.6578 32.8398C14.5619 32.8079 14.4341 32.8079 14.3383 32.7761C13.9868 32.7124 13.6672 32.6487 13.3477 32.585V24.8446C14.498 25.3543 15.7443 25.641 17.0544 25.641C20.7931 25.641 23.9886 23.4112 25.4266 20.2259H25.3946C25.9059 19.0792 26.1935 17.8369 26.1935 16.5309C26.1935 15.2249 25.9059 13.9827 25.4266 12.8359H33.2236C33.4473 13.9827 33.6071 15.2249 33.6071 16.4991Z"
                fill={color}
            />
        </svg>
    );
};

export default GloProsIcon;
