import { CSSProperties } from 'react';
import { useFormContext } from 'react-hook-form';
import {
    StyledTextField,
    CharactersInfo,
    Wrapper,
} from './CustomMultilineInput.styled';
import { debounce, get } from 'lodash';

interface InputProps {
    placeholder?: string;
    label: string;
    rows?: number;
    inputFormKey: string;
    borderRadius?: number;
    maxSymbols?: number;
    withErrorText?: boolean;
    style?: CSSProperties;
    disabled?: boolean;
    withDebounce?: boolean;
}

const CustomMultilineInput: React.FC<InputProps> = ({
    placeholder = '',
    label,
    rows = 6,
    inputFormKey,
    borderRadius = 6,
    maxSymbols,
    withErrorText = true,
    style,
    disabled,
    withDebounce = false,
}) => {
    const {
        register,
        formState: { isSubmitting, errors },
        watch,
        trigger,
    } = useFormContext();

    const { onChange, ...rest } = register(inputFormKey);

    const inputValue = watch(inputFormKey);
    const totalSymbols = inputValue ? inputValue.length : 0;
    const errorMessage = get(errors, inputFormKey)?.message;

    const delayedTrigger = debounce(trigger, 300);
    const debouncedOnchange = debounce(onChange, 300);

    return (
        <Wrapper style={style}>
            <StyledTextField
                fullWidth
                {...rest}
                $borderRadius={borderRadius}
                label={label}
                placeholder={placeholder}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                rows={rows}
                multiline
                error={!!errorMessage}
                onChange={(e) => {
                    withDebounce ? debouncedOnchange(e) : onChange(e);
                    errorMessage && delayedTrigger(inputFormKey);
                }}
                helperText={withErrorText ? errorMessage : null}
                disabled={isSubmitting || disabled}
                onBlur={() => trigger(inputFormKey)}
            />

            {maxSymbols && (
                <CharactersInfo
                    $withError={
                        !!errorMessage
                    }>{`${totalSymbols}/${maxSymbols}`}</CharactersInfo>
            )}
        </Wrapper>
    );
};

export default CustomMultilineInput;
