import { TextField, styled } from '@mui/material';
import { transientOptions } from 'helpers/transientOptions';

export const Wrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
});

export const StyledTextField = styled(
    TextField,
    transientOptions
)<{ $borderRadius: number }>(({ theme, $borderRadius }) => ({
    width: '100%',

    '& .MuiInputBase-root': {
        borderRadius: `${$borderRadius}px`,
        ...theme.fonts.body_2,
    },
}));

export const CharactersInfo = styled(
    'span',
    transientOptions
)<{ $withError?: boolean }>(({ theme, $withError }) => ({
    ...theme.fonts.caption,
    color: $withError ? theme.colors.errorText : theme.colors.secondaryColor_7,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
}));
