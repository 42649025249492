import { styled } from '@mui/material';
import { transientOptions } from 'helpers/transientOptions';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const muiRedColor = '#d32f2f!important';
const muiBlueFocusColor = '#1A76D2!important';
const muiGreyBorderColor = '#B9BABC!important';

export const StyledPhoneInput = styled(
    PhoneInput,
    transientOptions
)<{ $isOnFocus: boolean; $withError: boolean }>(
    ({ theme, $isOnFocus, $withError, disabled }) => ({
        '& .form-control': {
            height: '48px',
            ...theme.fonts.body_2,
            width: '100%!important',
            padding: '12px 14px 12px 58px!important',
            background: 'transparent!important',
            transition: 'none!important',
            border: $isOnFocus
                ? `2px solid ${muiBlueFocusColor}`
                : `1px solid ${$withError ? muiRedColor : muiGreyBorderColor}`,

            '&:hover': {
                border: disabled
                    ? 'none'
                    : $isOnFocus
                    ? 'none'
                    : '1px solid black!important',
                cursor: disabled ? 'default' : 'auto',
            },

            '&:focus': {
                boxShadow: 'none!important',
            },
        },

        '& .flag-dropdown': {
            borderRight: `1px solid ${theme.colors.secondaryColor_16}`,
            borderRadius: '4px!important',
        },

        '& .special-label': {
            color: disabled
                ? 'rgba(0, 0, 0, 0.38)'
                : $withError
                ? muiRedColor
                : $isOnFocus
                ? theme.colors.primaryColor
                : 'rgba(0, 0, 0, 0.6)',
            fontFamily: 'Roboto, Helvetica ,Arial, sans-serif',
            fontWeight: '400',
            fontSize: '0.5rem',
            letterSpacing: '0.00938em',
            left: '9px!important',
        },

        '& .MuiInputBase-input.MuiOutlinedInput-input': {
            color: disabled && 'rgba(0, 0, 0, 0.38)',
        },
    })
);

export const InputHelperText = styled(
    'p',
    transientOptions
)<{ $withError?: boolean }>(({ theme, $withError = false }) => ({
    ...theme.fonts.caption,
    color: $withError ? theme.colors.errorText : theme.colors.secondaryColor_7,
    margin: '3px 14px 0px 15px',
    letterSpacing: '0.03333em',
    fontWeight: '400',
    fontSize: '0.75rem',
}));
