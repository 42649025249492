export type Obj = Record<string, unknown> | Array<unknown>;

const snakeToCamel = (s: string) => {
    return s.replace(/(_\w)/g, (x) => x[1]!.toUpperCase());
};

const ucFirst = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

const snakeToPascal = (s: string) => s.split('_').map(ucFirst).join('');
const camelToPascal = (s: string) => ucFirst(s);
const kebabToTitle = (s: string) => s.replace(/-/g, ' ');
const snakeToTitle = (s: string) => s.replace(/_/g, ' ');

/**
 * @link https://github.com/peet/camel-to-snake/blob/master/index.js
 */
const camelToSnake = (s: string) => {
    return s
        .replace(/([a-z]|(?:[A-Z0-9]+))([A-Z0-9]|$)/g, function (_, $1, $2) {
            return $1 + ($2 && '_' + $2);
        })
        .toLowerCase();
};

const keysToSnakeFromCamel = (obj: Obj, removeNull = true) =>
    convertObjectKeys(obj, removeNull, camelToSnake);

const keysToCamelFromSnake = (obj: Obj, removeNull = true) =>
    convertObjectKeys(obj, removeNull);

const convertObjectKeys = (
    obj: Obj,
    removeNull = true,
    converter = snakeToCamel
): Obj => {
    if (Array.isArray(obj)) {
        return obj.map((x) =>
            convertObjectKeys(x as Obj, removeNull, converter)
        );
    }

    if (typeof obj !== 'object') {
        return obj;
    }

    const r: Obj = {};
    for (const key in obj) {
        if (removeNull && obj[key] === null) {
            continue;
        }

        r[converter(key)] =
            typeof obj[key] === 'object'
                ? convertObjectKeys(obj[key] as Obj, removeNull, converter)
                : obj[key];
    }

    return r;
};

export {
    ucFirst,
    convertObjectKeys,
    snakeToCamel,
    snakeToPascal,
    camelToPascal,
    camelToSnake,
    keysToSnakeFromCamel,
    keysToCamelFromSnake,
    kebabToTitle,
    snakeToTitle,
};
